import React from "react";
import styled from "styled-components";

import { Logo } from "./Logo";
import { HeaderBar } from "./HeaderBar";
import { Navigation } from "./Navigation";
import { MobileNavigation } from "./MobileNavigation";
import { brandDark } from "./Colors";

const StyledHeader = styled.header`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  z-index: 1;
  background-color: ${brandDark};

  ${HeaderBar}, ${Navigation} {
    display: none;
  }

  @media screen and (min-width: 900px) {
    ${HeaderBar}, ${Navigation} {
      display: flex;
    }
    ${MobileNavigation} {
      display: none;
    }
  }
`;

const StyledLogo = styled(Logo)`
  margin: 30px 30px 30px 20px;
  @media screen and (min-width: 900px) {
    margin: 60px 30px 60px 20px;
  }
  @media screen and (min-width: 1277px) {
    margin: 60px 30px 60px 90px;
  }
`;

export const Header = () => {
  return (
    <StyledHeader>
      <HeaderBar />
      <StyledLogo />
      <Navigation />
      <MobileNavigation />
    </StyledHeader>
  );
};
