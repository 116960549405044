import React from "react";
import styled from "styled-components";
import { graphql, Link, useStaticQuery } from "gatsby";
import { brandOrange, link, linkHover, linkActive, white } from "./Colors";

const StyledNavigation = styled.nav`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;

const StyledMainNav = styled.ul`
  background-color: ${white};
  height: 60px;
  padding-left: 25px;
  display: flex;
  margin-top: 85px;
  position: relative;

  :before {
    width: 60px;
    height: 60px;
    background-color: ${brandOrange};
    content: "";
    position: absolute;
    top: -15px;
    left: -15px;
    z-index: -1;
  }

  @media screen and (min-width: 1200px) {
    padding: 0 25px;
    height: 80px;
    :before {
      width: 80px;
      height: 80px;
      top: -25px;
      left: -25px;
    }
  }
`;

const StyledNavLink = styled(Link)`
  font-family: "Inria Sans";
  color: ${link};
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 700;
  &.active {
    background-color: ${linkActive};
  }
  :hover {
    background-color: ${linkHover};
  }
  @media screen and (min-width: 1200px) {
    padding: 0 25px;
    font-size: 19px;
  }
`;

export const Navigation = styled((props) => {
  const {
    site: {
      siteMetadata: { mainMenuItems },
    },
  } = useStaticQuery(graphql`
    query MainMenuQuery {
      site {
        siteMetadata {
          mainMenuItems {
            label
            href
          }
        }
      }
    }
  `);
  return (
    <StyledNavigation {...props}>
      <StyledMainNav>
        {mainMenuItems.map(({ label, href }) => (
          <StyledNavLink to={href} key={href} activeClassName="active">
            {label}
          </StyledNavLink>
        ))}
      </StyledMainNav>
    </StyledNavigation>
  );
})``;
