import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaYoutubeSquare,
} from "react-icons/fa";
import styled from "styled-components";
import { brandDark, white } from "./Colors";

import { Logo } from "./Logo";

const StyledFooter = styled.footer`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "logo"
    "menu"
    "social"
    "notice";
  background-color: ${brandDark};
  min-height: 320px;
  width: 100%;
  padding: 20px;
  color: #ffffff;

  @media screen and (min-width: 600px) {
    padding: 70px 20px 20px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "logo menu social"
      "notice notice notice";
  }

  @media (min-width: 1277px) {
    padding: 70px calc((100% - 1220px) / 2) 20px;
  }
`;

const StyledCopyRightNotice = styled.p`
  width: 100%;
  grid-area: notice;
  align-self: flex-end;
  text-align: center;
  @media screen and (min-width: 600px) {
    text-align: left;
  }
`;

const CopyRightNotice = () => {
  return (
    <StyledCopyRightNotice>
      © 2021 Jauno Fiziķu Skola. Visas tiesības aizsargātas
    </StyledCopyRightNotice>
  );
};

const StyledFooterMenu = styled.nav`
  grid-area: menu;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 600px) {
    align-items: flex-start;
  }
`;

const FooterMenuLink = styled(Link)`
  font-family: "Inria Sans";
  color: ${white};
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
`;

const FooterMenu = () => {
  const {
    site: {
      siteMetadata: { footerMenuItems },
    },
  } = useStaticQuery(graphql`
    query FooterMenuQuery {
      site {
        siteMetadata {
          footerMenuItems {
            label
            href
          }
        }
      }
    }
  `);
  return (
    <StyledFooterMenu>
      {footerMenuItems.map(({ label, href }) => (
        <FooterMenuLink to={href} key={href}>
          {label}
        </FooterMenuLink>
      ))}
    </StyledFooterMenu>
  );
};

const StyledSocialMenu = styled.nav`
  grid-area: social;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: baseline;

  @media screen and (min-width: 600px) {
    justify-content: flex-end;
  }
`;

const SocialLink = styled.a`
  color: ${white};
  margin: 5px;
`;

const SocialMenu = () => {
  return (
    <StyledSocialMenu>
      <SocialLink href="https://www.instagram.com/jfskola/">
        <FaInstagramSquare size={30} />
      </SocialLink>
      <SocialLink href="https://www.facebook.com/JFSkola/">
        <FaFacebookSquare size={30} />
      </SocialLink>
      <SocialLink href="https://www.youtube.com/channel/UC_V5Wzm6Waj6ouyIPyZOj7w">
        <FaYoutubeSquare size={30} />
      </SocialLink>
    </StyledSocialMenu>
  );
};

const FooterLogoWrapper = styled.div`
  grid-area: logo;
  display: flex;
  justify-content: center;
  align-content: baseline;

  @media screen and (min-width: 600px) {
    justify-content: flex-start;
  }
`;

export const Footer = () => {
  return (
    <StyledFooter>
      <FooterLogoWrapper>
        <Logo />
      </FooterLogoWrapper>
      <FooterMenu />
      <SocialMenu />
      <CopyRightNotice />
    </StyledFooter>
  );
};
