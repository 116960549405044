import React, { useEffect, useState } from "react";
import styled, { createGlobalStyle, css } from "styled-components";
import { graphql, Link, useStaticQuery } from "gatsby";
import {
  FiArrowRight,
  FiExternalLink,
  FiLock,
  FiSettings,
  FiUser,
} from "react-icons/fi";
import { brandOrange, lightHover, link, white } from "./Colors";
import { useAuth } from "../hooks/useAuth";
import { useUser } from "../hooks/useUser";
import { breakpoints, useScreenSize } from "../hooks/useScreenSize";

const StyledMobileNavigation = styled.div`
  position: absolute;
  top: 30px;
  right: 17px;
  z-index: 6;
`;

const BlockGlobalScroll = createGlobalStyle`
  body {
    overflow: hidden;
  } 
`;

const MobileNavigationOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #000000a6;
  z-index: 5;
`;

const StyledMobileNavigationSidebar = styled.nav`
  width: 100%;
  height: 100%;
  background-color: ${brandOrange};
  position: absolute;
  right: 0;
  top: 0;
  padding: 104px 37px 60px 20px;
  transition: 0.3s;

  @media screen and (min-width: 600px) {
    width: 400px;
  }
`;

const StyledMobileMenu = styled.ul`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${white};
  padding-bottom: 28px;
  margin-bottom: 28px;
`;

const StyledMobileMenuItem = styled.li``;

const StyledMobileMainMenuLink = styled(Link)`
  font-family: "Inria Sans";
  color: ${white};
  font-size: 24px;
  font-weight: 700;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 0 14px;
  cursor: pointer;

  :hover {
    color: ${lightHover};
  }
`;

const StyledMobileMenuLink = styled(StyledMobileMainMenuLink)`
  font-family: "PT Sans";
  color: ${white};
  font-size: 20px;
  font-weight: 400;
`;

const StyledMobileMenuButton = styled.button`
  width: 60px;
  height: 60px;
  background-color: ${white};
  border: none;
  border-radius: 0%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  :before {
    width: 60px;
    height: 60px;
    background-color: ${brandOrange};
    content: "";
    position: absolute;
    top: -15px;
    left: -15px;
    z-index: -1;
    cursor: initial;
  }
`;

const MenuBurger = styled.span`
  height: 3px;
  width: 30px;
  background-color: ${link};
  display: block;
  position: relative;
  transition: .15s cubic-bezier(.4,0,.2,1);

  :before {
    content: "";
    position: absolute;
    height: 3px;
    width: 30px;
    background-color: ${link};
    display: block;
    top: -7px;
    transition: transform .3s cubic-bezier(.4,0,.2,1);
  }
  :after {
    content: "";
    position: absolute;
    height: 3px;
    width: 30px;
    background-color: ${link};
    display: block;
    bottom: -7px;
    transition: transform .3s cubic-bezier(.4,0,.2,1);
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      background-color: ${white};
      :before {
        top: 0;
        transform: rotate(135deg);
      }
      :after {
        bottom: 0;
        transform: rotate(45deg);
      }
    `}}
`;

const MobileMenuButton = ({ isOpen, ...props }) => {
  return (
    <StyledMobileMenuButton {...props}>
      <MenuBurger isOpen={isOpen} />
    </StyledMobileMenuButton>
  );
};

export const MobileNavigation = styled((props) => {
  const {
    site: {
      siteMetadata: { mainMenuItems, topMenuItems },
    },
  } = useStaticQuery(graphql`
    query MobileMenuQuery {
      site {
        siteMetadata {
          mainMenuItems {
            label
            href
          }
          topMenuItems {
            label
            href
            requiresLogin
          }
        }
      }
    }
  `);
  const { isAuthenticated, logOut } = useAuth();
  const { user } = useUser();
  const { width } = useScreenSize();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (width > breakpoints.sm) {
      setIsOpen(false);
    }
  }, [width]);

  return (
    <>
      <StyledMobileNavigation {...props}>
        <MobileMenuButton onClick={() => setIsOpen(!isOpen)} isOpen={isOpen} />
      </StyledMobileNavigation>
      {isOpen && (
        <MobileNavigationOverlay>
          <BlockGlobalScroll />
          <StyledMobileNavigationSidebar>
            <StyledMobileMenu>
              {mainMenuItems.map(({ label, href }) => (
                <StyledMobileMenuItem key={href}>
                  <StyledMobileMainMenuLink to={href}>
                    {label}
                    <FiArrowRight />
                  </StyledMobileMainMenuLink>
                </StyledMobileMenuItem>
              ))}
            </StyledMobileMenu>
            {!!topMenuItems.length && (
              <StyledMobileMenu>
                {topMenuItems.map(
                  ({ label, href, external, requiresLogin }) => (
                    <StyledMobileMenuItem key={href}>
                      <StyledMobileMenuLink to={href}>
                        {label}
                        {!isAuthenticated && requiresLogin ? (
                          <FiLock />
                        ) : external ? (
                          <FiExternalLink />
                        ) : null}
                      </StyledMobileMenuLink>
                    </StyledMobileMenuItem>
                  )
                )}
              </StyledMobileMenu>
            )}
            <StyledMobileMenu>
              {user?.isAdmin && (
                <StyledMobileMenuItem>
                  <StyledMobileMenuLink to="/admin">
                    Administrācijas panelis
                    <FiSettings />
                  </StyledMobileMenuLink>
                </StyledMobileMenuItem>
              )}
              <StyledMobileMenuItem>
                <StyledMobileMenuLink to="/profils">
                  {isAuthenticated ? (
                    <>
                      {user?.displayName || "Profils"}
                      <FiUser />
                    </>
                  ) : (
                    <>
                      Dalībniekiem
                      <FiLock />
                    </>
                  )}
                </StyledMobileMenuLink>
              </StyledMobileMenuItem>
              {isAuthenticated && (
                <StyledMobileMenuItem>
                  <StyledMobileMenuLink onClick={() => logOut()} as="div">
                    Iziet
                  </StyledMobileMenuLink>
                </StyledMobileMenuItem>
              )}
            </StyledMobileMenu>
          </StyledMobileNavigationSidebar>
        </MobileNavigationOverlay>
      )}
    </>
  );
})``;
