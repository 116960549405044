import React from "react";
import styled from "styled-components";

import { GlobalStyle } from "./GlobalStyle";
import { Header } from "./Header";
import { Footer } from "./Footer";

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.main`
  flex-shrink: 0;
  flex-grow: 1;
  padding: 20px;
  @media (min-width: 600px) {
    padding: 50px 20px 60px;
  }
  @media (min-width: 1277px) {
    padding: 50px calc((100% - 1220px) / 2) 60px;
  }
`;

export const Layout = ({ children }) => {
  return (
    <StyledLayout>
      <GlobalStyle />
      <Header />
      <Content>{children}</Content>
      <Footer />
    </StyledLayout>
  );
};
