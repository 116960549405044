import { useCallback, useEffect, useState } from "react";

export const breakpoints = {
  xs: 600,
  sm: 960,
  md: 1280,
  lg: 1920,
};

const getBreakpointRange = (width) => {
  if (width < breakpoints.xs) {
    return "xs";
  } else if (width < breakpoints.sm) {
    return "sm";
  } else if (width < breakpoints.md) {
    return "md";
  } else if (width < breakpoints.lg) {
    return "lg";
  } else {
    return "xl";
  }
};

export const useScreenSize = () => {
  const isClient = typeof window === "object";
  const getSize = useCallback(() => {
    return {
      width: isClient ? window.innerWidth : 0,
      height: isClient ? window.innerHeight : 0,
      screen: isClient ? getBreakpointRange(window.innerWidth) : "sm",
    };
  }, [isClient]);

  const [screenSize, setScreenSize] = useState(getSize());

  useEffect(() => {
    if (!isClient) {
      return;
    }

    const handleResize = () => {
      setScreenSize(getSize());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [getSize, isClient]);

  return screenSize;
};
