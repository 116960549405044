import React from "react";
import styled from "styled-components";
import { graphql, Link, useStaticQuery } from "gatsby";
import { FiLock, FiExternalLink, FiHome, FiSettings } from "react-icons/fi";

import { brandOrange, white } from "./Colors";
import { useAuth } from "../hooks/useAuth";
import { useUser } from "../hooks/useUser";

const StyledHeaderBar = styled.nav`
  width: 100%;
  height: 40px;
  display: flex;
  padding: 0 60px;
  background-color: ${brandOrange};
`;

const StyledHeaderBarLeftNav = styled.ul`
  display: flex;
`;

const StyledHeaderBarRightNav = styled(StyledHeaderBarLeftNav)`
  margin-left: auto;
`;

const StyledHeaderBarLink = styled(Link)`
  font-family: "Inria Sans";
  color: ${white};
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  :hover {
    background-color: #00000014;
  }
`;

const LockIcon = styled(FiLock)`
  margin-left: 5px;
`;

const ExternalIcon = styled(FiExternalLink)`
  margin-left: 5px;
`;

export const HeaderBar = styled((props) => {
  const { isAuthenticated, logOut } = useAuth();
  const { user } = useUser();

  const {
    site: {
      siteMetadata: { topMenuItems },
    },
  } = useStaticQuery(graphql`
    query TopMenuQuery {
      site {
        siteMetadata {
          topMenuItems {
            label
            href
            requiresLogin
          }
        }
      }
    }
  `);

  return (
    <StyledHeaderBar {...props}>
      <StyledHeaderBarLeftNav>
        <StyledHeaderBarLink to="/" title="Sākums">
          <FiHome size={18} />
        </StyledHeaderBarLink>
        {topMenuItems.map(({ href, label, requiresLogin, external }) => (
          <StyledHeaderBarLink to={href}>
            {label}{" "}
            {!isAuthenticated && requiresLogin ? (
              <LockIcon />
            ) : external ? (
              <ExternalIcon />
            ) : null}
          </StyledHeaderBarLink>
        ))}
      </StyledHeaderBarLeftNav>
      <StyledHeaderBarRightNav>
        {user?.isAdmin && (
          <StyledHeaderBarLink to="/admin">
            <FiSettings />
          </StyledHeaderBarLink>
        )}
        <StyledHeaderBarLink to="/profils">
          {isAuthenticated ? (
            user?.displayName || "Profils"
          ) : (
            <>
              Dalībniekiem
              <LockIcon />
            </>
          )}{" "}
        </StyledHeaderBarLink>
        {isAuthenticated ? (
          <StyledHeaderBarLink onClick={() => logOut()} as="div">
            Iziet
          </StyledHeaderBarLink>
        ) : null}
      </StyledHeaderBarRightNav>
    </StyledHeaderBar>
  );
})``;
